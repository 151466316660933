<template>
  <dialog ref="dialog" class="sp-dialog" :class="classModifiers" :style="styleVariables">
    <slot />
  </dialog>
</template>

<script setup>
import { onClickOutside, onKeyDown } from "@vueuse/core";
import { computed, nextTick, onMounted, ref, watch } from "vue";

const emit = defineEmits(["update-open", "close"]);

const props = defineProps({
  /**
   * The model value of the component.
   * If true the dialog will be shown.
   *
   * @type {Boolean}
   * @default false
   */
  open: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether to hide the backdrop or not.
   * If true the backdrop will not be rendered.
   *
   * @type {Boolean}
   * @default false
   */
  noBackdrop: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "300px",
  },
  /**
   * If true the dialog will not be deactivated when clicking outside of it or pressing esc key.
   *
   * @type {Boolean}
   * @default false
   */
  persistent: {
    type: Boolean,
    default: false,
  },
  /**
   * If true the dialog will be fullscreen.
   *
   * @type {Boolean}
   * @default false
   */
  fullscreen: {
    type: Boolean,
    default: false,
  },
});

const dialog = ref(null);
const mounted = ref(false);

const open = ref(props.open);

watch(open, (value) => emit("update-open", value));
watch(
  () => props.open,
  (value) => (open.value = value),
);

const classModifiers = computed(() => ({
  "--no-backdrop": props.noBackdrop,
  "--persistent": props.persistent,
  "--fullscreen": props.fullscreen,
}));

const styleVariables = computed(() => ({
  "--width": props.width,
}));

function showModal() {
  if (!mounted.value) {
    return;
  }

  dialog.value.showModal();
}

function closeModal() {
  dialog.value.close();
}

function registerDialogEvents(dialog) {
  dialog.addEventListener("close", onDialogCloseEvent);
  dialog.addEventListener("cancel", onDialogCloseEvent);
}

function onDialogCloseEvent() {
  hideDialog();
  emit("close");
}

function hideDialog() {
  open.value = false;
}

function conditionalHideDialog() {
  if (!props.persistent) {
    hideDialog();
  }
}

function onOpenChange(isOpen) {
  if (isOpen) {
    showModal();
  } else {
    closeModal();
  }
}

onMounted(() => {
  registerDialogEvents(dialog.value);
  mounted.value = true;

  if (open.value) {
    nextTick(() => showModal());
  }
});

onKeyDown("Escape", () => {
  conditionalHideDialog();
});

onClickOutside(dialog, () => {
  conditionalHideDialog();
});

watch(
  () => open.value,
  (isOpen) => onOpenChange(isOpen),
);
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-dialog {
  --width: 800px;

  margin: auto;
  width: var(--width);
  max-width: var(--width);
  border: none;
  overflow-y: hidden;

  &[open] {
    animation: fade 0.5s ease forwards;

    &::backdrop {
      display: none;
    }

    &:not(.--no-backdrop) {
      &::backdrop {
        display: block;
        animation: backdrop-fade 0.5s ease forwards;
      }
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes backdrop-fade {
    from {
      background: transparent;
    }
    to {
      background: rgba(0, 0, 0, 0.523);
    }
  }
}

// MODIFIERS
.sp-dialog {
  &.--no-backdrop {
    box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.2);
  }
  &.--fullscreen {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}
</style>
