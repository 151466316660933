<template>
  <div class="sp-list-item" :class="modifiers" :data-value="value">
    <div class="prepend">
      <slot name="prepend">
        <sp-icon v-if="prependIcon" :name="prependIcon" class="sp-list-item__prepend-icon" />
      </slot>
    </div>
    {{ title }}
    <div class="sp-list-item__subtitle" v-if="subtitle">
      {{ subtitle }}
    </div>
    <div class="append">
      <slot name="append">
        <sp-icon v-if="appendIcon" :name="appendIcon" class="sp-list-item__append-icon" />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  title: {
    type: [String, Number],
    default: undefined,
  },
  subtitle: {
    type: [String, Number],
    default: undefined,
  },
  value: {
    type: [String, Number, Boolean, Object, Array],
    default: undefined,
  },
  prependIcon: {
    type: String,
    default: undefined,
  },
  appendIcon: {
    type: String,
    default: undefined,
  },
});

const modifiers = computed(() => ({
  "--active": props.active,
  "--focused": props.focused,
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-list-item {
  --list-item-hover-background-color: var(--sp-list-item-hover-background-color, #eee);
  --list-item-active-background-color: var(--sp-list-item-active-background-color, #ddd);
  --list-item-focused-background-color: var(--sp-list-item-focused-background-color, #ccc);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &__subtitle {
    display: block;
    font-size: 0.875rem;
    color: grey;
  }

  &:hover {
    background-color: var(--list-item-hover-background-color);
  }

  &.--active {
    background-color: var(--list-item-active-background-color);
  }

  &:focus,
  &.--focused {
    background-color: var(--list-item-focused-background-color);
  }
}
</style>
