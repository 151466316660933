<template>
  <div class="menu" @keydown.esc="isActive = false">
    <slot name="activator" class="menu-activator" @click.prevent="onActivatorClick" />

    <div class="menu-content" :class="modifiersClass" @click="onContentClick">
      <slot />
    </div>
  </div>
</template>

<script setup>
/**
 * The SpMenu component shows a menu at the position of the element used to activate it.
 *
 * @displayName Menu
 * @group Custom Elements
 * @component sp-menu
 */
import { computed, ref, watch } from "vue";

const emit = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  /**
   * The element, component, or querySelector string to use as the activator.
   * TODO: impl.
   */
  activator: {
    type: [String, HTMLElement],
    default: undefined,
  },
  id: {
    type: String,
    default: undefined,
  },
  height: {
    type: [String, Number],
    default: undefined,
  },
  minHeight: {
    type: [String, Number],
    default: undefined,
  },
  maxHeight: {
    type: [String, Number],
    default: undefined,
  },
  width: {
    type: [String, Number],
    default: undefined,
  },
  minWidth: {
    type: [String, Number],
    default: undefined,
  },
  maxWidth: {
    type: [String, Number],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  closeOnContentClick: {
    type: Boolean,
    default: true,
  },
});

const isActive = ref(props.value);

watch(isActive, (value) => emit("input", value));
watch(
  () => props.value,
  (value) => (isActive.value = value),
);

const modifiersClass = computed(() => ({
  "--disabled": props.disabled,
  "--is-active": isActive.value,
}));

function onActivatorClick() {
  isActive.value = true;
}

function onContentClick() {
  if (props.closeOnContentClick && isActive) {
    isActive.value = false;
  }
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.menu {
  position: relative;
}

.menu-activator {
  cursor: pointer;
  display: block;
  position: relative;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: var(--sp-menu-content-background-color, #ffffff);
  min-width: 160px;
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
  width: 100%;

  &.--is-active {
    display: block;
  }
}
</style>
