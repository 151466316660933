<template>
  <component class="sp-button" :is="tag" :class="classModifiers" :disabled="disabled">
    <span class="sp-button__content">
      <sp-icon v-if="icon" :url="icon" :size="size" />
      <slot v-else>
        {{ label }}
      </slot>
    </span>
  </component>
</template>

<script setup>
import { computed } from "vue";
import { useBooleanModifiers } from "../../composables/classes";
import { useColor } from "../../composables/color";
import { useSize } from "../../composables/size";
import { useVariant } from "../../composables/variant";

const props = defineProps({
  tag: {
    type: String,
    default: "button",
    validator: (value) => ["button", "a"].includes(value),
  },
  label: {
    type: String,
    default: undefined,
  },
  color: {
    type: String,
    validator: (value) => ["primary", "secondary", "danger"].includes(value),
    default: "primary",
  },
  size: {
    type: String,
    validator: (value) => ["x-small", "small", "medium", "large", "default"].includes(value),
    default: "default",
  },
  variant: {
    type: String,
    validator: (value) => ["text", "text-inline", "flat", "elevated", "outlined", "plain"].includes(value),
    default: "flat",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Apply a specific icon using the sp-icon component.
   * The button will become round.
   *
   * @type {String|Boolean}
   * @default false
   */
  icon: {
    type: [String, Boolean],
    default: false,
  },
});

const { colorModifiers } = useColor(props);
const { variantModifiers } = useVariant(props);
const { sizeModifiers } = useSize(props);
const { booleanModifiers } = useBooleanModifiers(props, "icon");

const classModifiers = computed(() => [
  ...colorModifiers.value,
  ...variantModifiers.value,
  ...sizeModifiers.value,
  ...booleanModifiers.value,
]);
</script>

<style>
:host {
  display: block;
  --button-min-width: var(--sp-ce-button-min-width, 9rem);
  --button-width: auto;
  width: var(--button-width);
}
</style>

<style lang="scss" scoped>
.sp-button {
  --bg-color: var(--sp-ce-button-bg-color, #fefefe);
  --text-color: var(--sp-ce-button-text-color, currentColor);
  --border-color: var(--sp-ce-button-border-color, transparent);
  --hover-border-color: var(--sp-ce-button-hover-border-color, transparent);
  --hover-bg-color: var(--sp-ce-button-hover-bg-color, transparent);
  --hover-bg-opacity: var(--sp-ce-button-hover-bg-opacity, 1);
  --border-width: var(--sp-ce-button-border-width, 0);
  --border-style: var(--sp-ce-button-border-style, solid);
  --border-radius: var(--sp-ce-button-border-radius, 0);
  --transition-time: 0.3s;
  --size: var(--sp-ce-button-size, 2.75rem);
  --font-family: var(--sp-ce-button-font-family, inherit);
  --font-weight: var(--sp-ce-button-font-weight, normal);

  align-items: center;
  background: var(--bg-color);
  border: var(--border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--text-color);
  cursor: pointer;
  display: inline-grid;
  font-family: var(--font-family);
  font-size: var(--sp-ce-button-font-size, var(--sp-sys-body-font-size, 1rem));
  font-weight: var(--font-weight);
  gap: var(--sp-ce-button-gap, 0.25rem);
  grid-template-areas: "prepend content append";
  grid-template-columns: max-content auto max-content;
  height: var(--size);
  justify-content: center;
  min-width: var(--button-min-width);
  width: var(--button-width);
  padding: var(--sp-ce-button-padding, 0 1rem);
  position: relative;
  text-decoration: none;
  vertical-align: middle;

  // ::before controls the background hover
  // ::after controls the focus
  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    border-radius: var(--border-radius);
    transition: opacity var(--transition-time) linear;
  }

  // Element for Background hover
  &::before {
    background: var(--hover-bg-color);
  }

  &:hover {
    &::before {
      opacity: var(--hover-bg-opacity);
    }

    color: var(--hover-text-color);
    border-color: var(--hover-border-color);
  }

  &.--variant-outlined {
    --border-width: var(--sp-ce-button-outlined-border-width, 1px);
  }

  &.--variant-text-inline {
    padding: 0 !important;
    height: auto !important;
    min-width: 0 !important;
    gap: 0 !important;

    &::after,
    &::before {
      top: -0.75ex;
      right: -0.5ch;
      bottom: -0.75ex;
      left: -0.5ch;
    }
  }

  &:not([disabled="false"])[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  // Sizes
  &.--size-x-small {
    --size: var(--sp-ce-button-x-small-height, 1.5rem);
    font-size: var(--sp-ce-button-x-small-font-size, 0.75rem);
    padding: var(--sp-ce-button-x-small-padding, 0 0.5rem);
  }

  &.--size-small {
    --size: var(--sp-ce-button-small-height, 2rem);
    font-size: var(--sp-ce-button-small-font-size, 0.875rem);
    padding: var(--sp-ce-button-small-padding, 0 0.75rem);
  }

  &.--size-large {
    --size: var(--sp-ce-button-large-height, 3.75rem);
    font-size: var(--sp-ce-button-large-font-size, 1.375rem);
    padding: var(--sp-ce-button-large-padding, 0 1.875rem);
  }

  &.--icon {
    --border-radius: 50%;
    --icon-size: var(--size);
    padding: 0;
    min-width: var(--size);
    width: var(--size);
    justify-content: center;
    align-items: center;
    grid-template-areas: "content";
    grid-template-columns: auto;
    grid-template-rows: auto;

    sp-icon {
      --size: var(--icon-size);
      --fill: var(--text-color);
    }
  }

  // Colors
  @each $color in primary, secondary, danger {
    &.--color-#{$color} {
      --text-color: var(--sp-ce-button-#{$color}-text-color, #fff);
      --bg-color: var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666));
      --hover-text-color: var(--sp-ce-button-#{$color}-hover-text-color, #fff);
      --hover-bg-color: var(--sp-ce-button-#{$color}-hover-bg-color, var(--bg-color));

      &.--variant-text-inline,
      &.--variant-text {
        --bg-color: transparent;
        --text-color: var(
          --sp-ce-button-#{$color}-variant-text-text-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-text-color: var(--sp-ce-button-#{$color}-hover-variant-text-text-color, var(--text-color));
        --hover-bg-color: var(
          --sp-ce-button-#{$color}-hover-outlined-bg-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-bg-opacity: var(--sp-ce-button-#{$color}-hover-variant-text-bg-opacity, 0.1);
      }

      &.--variant-outlined {
        --bg-color: transparent;
        --text-color: var(
          --sp-ce-button-#{$color}-outlined-text-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );

        --border-color: var(
          --sp-ce-button-#{$color}-outlined-border-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-text-color: var(
          --sp-ce-button-#{$color}-hover-outlined-text-color,
          var(--sp-ce-button-#{$color}-text-color, #fff)
        );
        --hover-bg-color: var(
          --sp-ce-button-#{$color}-hover-outlined-bg-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
        --hover-border-color: var(
          --sp-ce-button-#{$color}-outlined-hover-border-color,
          var(--sp-ce-button-#{$color}-bg-color, var(--sp-sys-color-#{$color}, #666))
        );
      }
    }
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;

    &::after {
      opacity: 1;
      outline: var(--sp-ce-button-focus-outline, 1px solid #ccc);
      box-shadow: var(--sp-ce-button-focus-box-shadow, none);
    }
  }
}

.sp-button__content {
  z-index: 1;
}
</style>
