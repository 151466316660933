<template>
  <svg class="sp-caret" :width="size" :class="classModifiers" :height="size" :fill="color" viewBox="0 0 27 27">
    <path
      d="m18.275 10.69-4.565 4.533-4.442-4.42a.822.822 0 0 0-1.145-.004.823.823 0 0 0-.01 1.15l5.027 4.994a.818.818 0 0 0 1.141 0l5.135-5.102a.798.798 0 0 0 .004-1.156.803.803 0 0 0-1.145.005z"
    />
  </svg>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "32",
  },
  color: {
    type: String,
    default: "currentColor",
  },
});

const classModifiers = computed(() => ({
  "--is-active": props.active,
}));
</script>

<style>
:host {
  display: inline-block;
}
</style>

<style lang="scss" scoped>
.sp-caret {
  transform: rotate(0);
  fill: var(--color);
  transition: transform 0.2s ease-in-out;

  &.--is-active {
    transform: rotate(-180deg);
  }
}
</style>
