import * as vue from "vue";
import { toKebabCase } from "../shared/utils/string";

/**
 * Registers all custom elements.
 *
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 */
export function register(props = {}) {
  const components = import.meta.glob("./components/**/*.ce.vue", { eager: true });

  for (const [path, component] of Object.entries(components)) {
    const elementConstructor = defineCustomElement(component.default, props);
    registerCustomElement(path, elementConstructor);
  }

  // Set the visibility of the custom elements to visible.
  // This is required to prevent a flickering effect when the custom elements are loaded.
  // See app/assets/stylesheets/custom_elements/setup.scss.erb for more information.
  document.documentElement.style.setProperty("--sp-ce-visibility", "visible");
}

/**
 * Defines a custom element.
 * If applicable, the props passed to the custom element will be used as the default values for the component props.
 *
 * @param {vue.VueElement} component
 * @param {vue.ComponentObjectPropsOptions} props - Default values for the component props.
 * @returns {vue.VueElementConstructor<{}>}
 */
function defineCustomElement(component, props) {
  return vue.defineCustomElement({
    ...component,
    props: prepareProps(component, props),
  });
}

/**
 * Registers a custom element.
 *
 * The name of the custom element will be derived from the custom element file name.
 * The custom element will be registered under the "sp-" prefix.
 * For example, the custom element file name "MyComponent.ce.vue" will be registered as "sp-my-component".
 *
 * @param {string} path
 * @param {vue.VueElementConstructor<{}>} elementConstructor
 */
function registerCustomElement(path, elementConstructor) {
  const fileName = path.split("/").at(-1);
  const componentName = toKebabCase(fileName.replace(".ce.vue", ""));
  const isAlreadyRegistered = customElements.get(componentName);

  if (isAlreadyRegistered) {
    return;
  }

  customElements.define(componentName, elementConstructor);
}

/**
 * Enriches the component props with the props passed to the custom element.
 *
 * @param {vue.VueElement} component
 * @param {vue.ComponentObjectPropsOptions} props
 * @returns vue.ComponentObjectPropsOptions
 */
function prepareProps(component, props) {
  const reducer = (acc, [key, value]) => {
    if (value.external && key in props) {
      value = { ...value, default: props[key] };
    }

    return { ...acc, [key]: value };
  };

  return Object.entries(component.props ?? {}).reduce(reducer, {});
}
