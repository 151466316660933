<template>
  <div class="sp-text-field">
    <div class="sp-text-field-content">
      <div>
        <slot name="prepend" @click="onPrependClick">
          <sp-icon v-if="prependIcon" :name="prependIcon" class="sp-text-field-content__icon" />
        </slot>
      </div>
      <div class="sp-text-field-content__input-container">
        <slot />
        <input
          class="sp-text-field-content__input-field"
          :type="type"
          :value="model"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          :inputmode="inputmode"
          @input.stop.prevent="onInput"
        />
      </div>
      <div>
        <slot name="append" class="append-slot" @click="onAppendClick">
          <sp-icon v-if="appendIcon" :name="appendIcon" class="sp-text-field-content__icon" />
        </slot>
      </div>
    </div>

    <sp-linear-progress-bar v-if="loading" class="sp-text-field__loader" />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const emit = defineEmits(["input", "click-append", "click-prepend"]);

const props = defineProps({
  /**
   * Creates a sp-icon component before default content in the prepend slot.
   *
   * @type {String}
   * @default undefined
   */
  prependIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Creates a sp-icon component after default content in the append slot.
   *
   * @type {String}
   * @default undefined
   */
  appendIcon: {
    type: String,
    default: undefined,
  },
  /**
   * Removes the ability to click or target the input.
   *
   * @type {Boolean}
   * @default false
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Removes the ability to edit the input’s value.
   *
   * @type {Boolean}
   * @default false
   */
  readonly: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the input’s inputmode attribute.
   *
   * @type {String}
   * @default undefined
   */
  inputmode: {
    type: String,
    default: undefined,
    validator: (value) => {
      const validValues = ["none", "text", "decimal", "numeric", "tel", "search", "email", "url"];
      return validValues.includes(value);
    },
  },
  /**
   * The model value of the component.
   *
   * @type {any}
   * @default null
   */
  value: {
    type: [String, Number, Boolean, Object, Array],
    default: null,
  },
  /**
   * Sets the input’s placeholder text.
   *
   * @type {String}
   * @default undefined
   */
  placeholder: {
    type: String,
    default: undefined,
  },
  /**
   * Sets the input’s type attribute.
   *
   * @type {String}
   * @default "text"
   */
  type: {
    type: String,
    default: "text",
  },
  /**
   * Sets the input’s loading state.
   * If true, shows a linear progress bar.
   *
   * @type {Boolean}
   * @default false
   */
  loading: {
    type: Boolean,
    default: false,
  },
});

const model = ref(props.value);
watch(model, () => emit("input", model.value));
watch(
  () => props.value,
  (value) => (model.value = value),
);

function onInput(event) {
  model.value = event.target.value;
}

function onPrependClick() {
  emit("click-prepend");
}

function onAppendClick() {
  emit("click-append");
}
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-text-field {
  border: 1px solid var(--sp-ce-text-field-border-color, #ccc);
  border-radius: var(--sp-ce-text-field-border-radius, 0.3rem);
  box-sizing: border-box;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.sp-text-field-content {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}

.sp-text-field-content__input-container {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  padding-inline: var(--sp-ce-text-field-padding-inline, 1rem);
  padding-block: var(--sp-ce-text-field-padding-block, 0);
}

.sp-text-field-content__input-field {
  display: flex;
  flex: 1;
  border: none;
  outline: none;
  font-size: var(--sp-ce-text-field-font-size, var(--sp-sys-form-field-font-size, 1rem));
  font-family: var(--sp-ce-text-field-font, var(--sp-sys-form-field-font, sans-serif));
  background-color: transparent;
  width: 100%;
  min-width: 100%;

  min-width: var(--sp-ce-text-field-min-width, 10ch);
  min-height: var(--sp-ce-text-field-min-height, 3rem);
  width: var(--sp-ce-text-field-width, 100%);
  font-size: var(--sp-ce-text-field-font-size, 1rem);

  &[inputmode="none"] {
    cursor: pointer;
  }
}

.sp-text-field__loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
