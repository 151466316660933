<template>
  <div class="sp-linear-progress-bar" :style="style"></div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  height: {
    type: String,
    default: undefined,
  },
  color: {
    type: String,
    default: undefined,
  },
});

const style = computed(() => ({
  "--height": props.height,
  "--color": props.color,
}));
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
.sp-linear-progress-bar {
  --height: var(--sp-ce-linear-progress-bar-height, 0.2rem);
  --color: var(--sp-ce-linear-progress-bar-color, #007bff);

  width: 100%;
  height: var(--height);
  background-color: #ddd;
  position: relative;
  overflow: hidden;
}

.sp-linear-progress-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color);
  transform: translateX(-100%);
  animation: load 2s infinite linear;
}

@keyframes load {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
