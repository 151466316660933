<template>
  <div class="sp-list" ref="list" @keydown.enter="onKeydownEnter" aria-activedescendant="focusedIndex">
    <sp-list-item
      v-for="(item, index) in items"
      :key="index"
      :active="isSelected(item)"
      :aria-selected="focusedIndex === index"
      :focused="focusedIndex === index"
      :title="getItemTitle(item)"
      :subtitle="itemSubtitle ? getItemProperty(item, itemSubtitle) : undefined"
      :value="getItemValue(item)"
      @click="updateSelection(item)"
    />
  </div>
</template>

<script setup>
import { useEventListener } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";

const emit = defineEmits(["update-selected"]);

defineExpose({
  focus,
});

const props = defineProps({
  /**
   * Can be an array of objects or strings.
   *
   * By default objects should have title and value properties.
   * Keys to use for these can be changed with the item-title, item-value, and item-props props.
   * If strings are used, they will be used for both title and value.
   *
   * @type {Array<String|Object>}
   * @default []
   */
  items: {
    type: Array,
    default: () => [],
  },
  /**
   * Property on supplied items that contains its title.
   *
   * @type {String}
   * @default "title"
   */
  itemTitle: {
    type: String,
    default: "title",
  },
  /**
   * Property on supplied items that contains its subtitle.
   *
   * @type {String}
   * @default undefined
   */
  itemSubtitle: {
    type: String,
    default: undefined,
  },
  /**
   * Property on supplied items that contains its value.
   *
   * @type {String}
   * @default "value"
   */
  itemValue: {
    type: String,
    default: "value",
  },
  /**
   * An array containing the values of currently selected items.
   *
   * @type {Array}
   * @default undefined
   */
  selected: {
    type: Array,
    default: undefined,
  },
  /**
   * Changes the selection behavior to return the object directly rather than the value specified with item-value.
   *
   * @type {Boolean}
   * @default false
   */
  returnObject: {
    type: Boolean,
    default: false,
  },
  /**
   * Changes the selection behavior to allow multiple selections.
   *
   * @type {Boolean}
   * @default false
   */
  multiple: {
    type: Boolean,
    default: false,
  },
});

const list = ref(null);

// FIXME: This is a workaround for slot based event listeners.
// Listen for keydown events on the document and forward them to the list is quite expensive.
// We should be able to listen for keydown events on the list directly, even if it's a slot.
useEventListener(document, "keydown", (e) => {
  if (e.key === "Enter") {
    onKeydownEnter();
  } else if (e.key === "ArrowDown") {
    focus("next");
  } else if (e.key === "ArrowUp") {
    focus("previous");
  }
});

const supportsMultiple = computed(() => props.multiple === true || props.multiple === "true");

const model = ref(props.selected ?? (supportsMultiple.value ? [] : null));
watch(model, (value) => emit("update-selected", value));
watch(
  () => props.selected,
  (value) => (model.value = value),
);

const focusedIndex = ref(null);

function focus(location) {
  const length = props.items?.length ?? 0;

  if (length === 0) {
    focusedIndex.value = null;
  }

  let index;

  if (location === "first") {
    index = 0;
  } else if (location === "last") {
    index = length - 1;
  } else if (location === "next") {
    index = focusedIndex.value === null ? 0 : focusedIndex.value + 1;
  } else if (location === "previous") {
    index = focusedIndex.value === null ? length - 1 : focusedIndex.value - 1;
  }

  if (index < 0) {
    index = length - 1;
  } else if (index >= length) {
    index = 0;
  }

  focusedIndex.value = index;

  nextTick(() => {
    const el = list.value.querySelector("[aria-selected=true]");
    el?.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "auto" });
  });
}

function getItemTitle(item) {
  return getItemProperty(item, props.itemTitle);
}

function getItemValue(item) {
  return getItemProperty(item, props.itemValue);
}

function getItemProperty(item, property) {
  return typeof item === "string" ? item : item[property];
}

function isSelected(item) {
  return model.value?.includes?.(getItemValue(item));
}

function updateSelection(item) {
  if (supportsMultiple.value) {
    toggleMultipleSelection(item);
  } else {
    const value = getItemValue(item);
    model.value = value;
  }
}

function toggleMultipleSelection(item) {
  const value = getItemValue(item);
  const index = model.value.indexOf(value);

  if (index === -1) {
    model.value.push(value);
  } else {
    model.value.splice(index, 1);
  }
}

function onKeydownEnter() {
  if (focusedIndex.value !== null) {
    updateSelection(props.items[focusedIndex.value]);
  }
}
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-list {
  max-height: 20rem;
  overflow-y: auto;
  width: 100%;
}
</style>
