<template>
  <div v-if="svg" ref="customContainer" v-html="svg" :fill="iconFillColor" :class="classNames"></div>

  <component v-else-if="name" :is="tag" v-bind="$attrs" class="icon-wrapper">
    <svg class="icon" :view-box="viewBox" :class="classNames" :style="svgStyles">
      <use :href="iconHref" :fill="iconFillColor" />
    </svg>
  </component>

  <div v-else :class="classNames">
    <slot ref="customContainer" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: false,
    default: undefined,
  },
  svg: {
    type: String,
    required: false,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
  fillColor: {
    type: String,
    default: undefined,
  },
  spriteUrl: {
    type: String,
    external: true,
    default: undefined,
  },
  viewBox: {
    type: String,
    default: "0 0 32 32",
  },
  size: {
    type: String,
    default: "2rem",
  },
  tag: {
    type: String,
    default: "div",
  },
});

const customContainer = ref(null);

const classNames = computed(() => ({ [`icon--${props.name}`]: props.name, "theme--dark": props.dark }));

const iconSpriteUrl = computed(() => props.spriteUrl ?? window.iconSpriteUrl?.());
const iconHref = computed(() => `${iconSpriteUrl.value}#icon-${props.name}`);
const iconFillColor = computed(() => props.fillColor);

const svgStyles = computed(() => ({ width: props.size, height: props.size }));

/**
 * Returns the element that should be used to set the fill color.
 * If the custom container is a slot, the first element in the slot is returned.
 * Otherwise the custom container itself is returned.
 *
 * @type {HTMLElement}
 */
const customContainerElement = computed(() => {
  if (!customContainer.value) {
    return null;
  }
  const elements = customContainer.value.assignedNodes?.()?.filter(({ nodeType }) => nodeType === Node.ELEMENT_NODE);
  return elements?.at(0) ?? customContainer.value;
});

/**
 * Sets the fill color on all paths and uses of the icon.
 * If the icon is not filled, nothing is done.
 * If the icon is filled, the fill color is set on all paths and uses.
 * If the fill color is undefined, the fill color is removed from all paths and uses.
 *
 * @param {String|undefined} fillColor - The fill color to set.
 */
function setFillColor(fillColor) {
  const container = customContainerElement.value;

  if (!container?.querySelector?.(".icon__use--filled")) {
    return;
  }

  container.querySelectorAll("path, use").forEach((path) => {
    if (fillColor) {
      path.setAttribute("fill", props.fillColor);
    } else {
      path.removeAttribute("fill");
    }
  });
}

watch(
  () => props.fillColor,
  (fillColor) => setFillColor(fillColor),
  { immediate: true },
);

const svgContainerElement = computed(() => customContainerElement.value?.querySelector?.("svg"));

function setIconSize() {
  for (const [name, value] of Object.entries(svgStyles.value)) {
    svgContainerElement.value?.style.setProperty(name, value);
  }
}

watch(
  () => props.size,
  () => setIconSize(),
  { immediate: true },
);

onMounted(async () => {
  if (props.url) {
    const response = await fetch(props.url);
    customContainer.value.innerHTML = await response.text();
  }
  if (props.fillColor) {
    setFillColor(props.fillColor);
  }
  setIconSize();
});
</script>

<style>
:host {
  --fill: black;
  --size: 2rem;
  display: block;
  max-width: var(--size);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  --icon-size: var(--size);
}

::slotted(svg) {
  height: var(--size);
  width: var(--size);
}

svg {
  height: var(--size);
  width: auto;
  fill: var(--fill);
}

.--color-white {
  --fill: white;
}
</style>
